<template>
  <!-- 关键词 文章列表 -->
  <div class="container">
    <!-- 导航 -->
    <V-ArticleNav class="headClass" :navList="navList"></V-ArticleNav>
    <div class="gWbc title"> 
      发布词条加工任务
    </div>
    <div class="gWbc basicInfo">
      <div class="bi_top_btn">填写基本信息</div>
      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>任务名称：</span>
        </div>
        <div class="inRight">
          <el-input class="right_input" v-model="release.taskname" @input="textLength" @blur="textRepetition"
           placeholder="请输入30字内的任务名称"></el-input>
          <div class="rightTs">提示：最多输入30个字，且任务名称不能重复</div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>任务简介：</span>
        </div>
        <div class="inRight">
          <el-input type="textarea" v-model="release.describe" resize="none" class="right_input" style="height:100px"
          placeholder="请输入任务简介"></el-input>
        </div>
      </div>

      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>词条领域：</span>
        </div>
        <div class="inRight select_input" style="width:auto;min-width:390px;max-width: 600px;">
          <el-cascader
            v-model="selEntry"
            :options="entry"
            collapse-tags
            :props="{multiple: true, checkStrictly: true}"
          ></el-cascader>
        </div>
      </div>

      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>成果格式： </span>
        </div>
        <div class="inRight">
          <div class="right_input right_input_radio">
            <el-radio v-model="release.resultformat" disabled label="pdf">pdf</el-radio>
          </div>
        </div>
      </div>
      <!-- <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>上传附件：</span>
        </div>
        <div class="inRight">
          <el-upload
            class="right_input right_input_btn"
            :action="fileUrl"
            :headers="{token}"
            :data="{userName:loginName,type:'1'}"
            :show-file-list="false"
            :on-error="updateError"
            :on-success="updateSuccess"
            :before-upload="beforeAvatarUpload">
            <el-button size="small" type="primary">
              {{release.fujianPath?fileName:'请上传附件'}}
            </el-button>
          </el-upload>
          <div class="rightTs">
            提示：仅支持 doc/docx/pdf 附件格式；
          </div>
        </div>
      </div> -->
    </div>

    <div class="gWbc basicInfo">
      <div class="bi_top_btn">设置商务要求</div>
      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>加工工期：</span>
        </div>
        <div class="inRight" style="width:380px;">
          <div class="inputs">
            <el-input class="right_input" @input="(e)=>{inputNum(e,'totaldays')}"
             v-model="release.totaldays"
             style="width:200px" placeholder="请输入天数(正整数)"></el-input>
            <span style="margin-left:10px">天</span>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>负责人酬劳：</span>
        </div>
        <div class="inRight">
          <div class="inputs">
            <el-input class="right_input" @input="(e)=>{inputNum(e,'fzr_totalmoney')}"
             style="width:200px" 
            placeholder="请输入知识币数(正整数)" v-model="release.fzr_totalmoney"></el-input>
            <span style="margin-left:10px">知识币</span>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>任务总酬劳：</span>
        </div>
        <div class="inRight">
          <div class="inputs">
            <el-input class="right_input" @input="(e)=>{inputNum(e,'totalmoney')}" style="width:200px"
             placeholder="请输入知识币数(正整数)" v-model="release.totalmoney"></el-input>
            <span style="margin-left:10px">知识币</span>
          </div>
        </div>
      </div>
      <div class="typeIn in_btn">
        <div class="inLeft_btn" @click="tapCancel">取消</div>
        <div class="inRight_bnt" @click="tapRelease">发布</div>
      </div>
    </div>

  </div>
</template> 

<script>
import ArticleNav from "@/components/article-nav/index.vue";
import {post,get} from "@/services/http.js";
import { QUERYED,UPDATES,INSERT,DELETE } from "@/services/dao.js";
import {Bringbill} from "@/js/common"; 
import api from "@/dev/api.ts";
import { Loading } from 'element-ui';
export default {
  components: {
    "V-ArticleNav": ArticleNav,
  }, 
  data() {
    return {
      navList:[{word:'发布'}],  //导航

      entry:[],   //词条数据
      selEntry: [], //被选择的词条数据

      release:{ //发布      
        taskname:'',  //任务名称
        describe:'',  //任务简介
        type:'',  //Text
        resultformat:'pdf',  //成果格式
        // fujianPath:'',  //附件路径
        totaldays:'',  //任务工期    
        totalmoney:'',  //总酬劳
        fzr_totalmoney:'',  //负责人酬劳
        status:0, //任务状态
        issueTime:0 //发布时间
      }, 

      fileUrl:api.LoginURL.concat('/common/entryUpFile'),  //上传文件路径
      fileName:'',  //上传文件名
      token:'',

      residueMoney:0,  //当前余额
      zsjg_prepay:0,  //用户被冻结余额
      loginName:'',  //用户登录名
      LoadingIcon:null,  //加载

      isExist:false,   //任务名称是否重复
    };
  },
  created(){
    this.LoadingIcon = Loading.service()
    this.LoadingIcon.close()
    let userid = this.$store.state.userinfo.id;   
    if(!userid){
      this.$alert('请登录后再发布!', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          this.$router.push({path:'/login'})
        }
      });
      return
    }
    this.token = localStorage.getItem('Authorization')
    this.release.userid = userid
    this.getUserInfo(userid)
    this.getentrylist()
  },
  methods: {
    //点击取消时
    tapCancel(){
      this.$router.go(-1)
    },
    //获取去用户信息
    async getUserInfo(id){
      let res = await QUERYED(
        "post","",
        "User(where:{id: {_eq:"+ id +"}}) { id name Money zsjg_prepay loginName }"
      );
      if(res.success){
        this.release.username = res.data.User[0].name
        this.residueMoney = res.data.User[0].Money
        this.loginName  = res.data.User[0].loginName
        this.zsjg_prepay  = res.data.User[0].zsjg_prepay
      }
    },
    //获取词条数据
    getentrylist(){
      // let entry = require('./entryField.json')
      // this.entry = entry
      // console.log(entry,123456789);
      get('/resources/trading/upload/html/entryField.json').then((e)=>{
        this.entry = e
      })
    },
    //判断输入的是否为数字 
    inputNum(e,name){
      this.release[name] = e.replace(/[^\d]/g,'' )
    },

    //判断值是否为空
    pdIsEmpty(e){
      return e.replace(/ /g,'')
    },

    //警告弹窗
    msgWarn(message,type){
      this.$message({
        message,type: type||'warning'
      });
    },

    //文件上传失败
    // updateError(err){
    //   console.log('err',err);
    //   this.msgWarn('文件上传失败,请重试')
    //   this.LoadingIcon.close()
    // },

    //限制字数
    textLength(e){
      if(e.length>30){
        this.msgWarn('最大长度为30个字')
        this.release.taskname = e.slice(0,30)
      }
    },

    //查看任务名称是否重复
    async textRepetition(e){
      let value = this.pdIsEmpty(e.target.value)
      if(value){
        let res = await QUERYED(
          "post","",
          "jgEntryPro_aggregate(where:{taskname: {_eq:\""+ value +"\"}}) { aggregate {count}}"
        );
        if(res.success){
          let isExist = res.data.jgEntryPro_aggregate.aggregate.count>0
          this.isExist = isExist
          if(isExist){
            this.msgWarn('当前任务名称已存在')
          }
        }
      }
    },

    //上传前文件判断
    // beforeAvatarUpload(file){
    //   let {name,size} = file
    //   let list = name?name.split('.'):[]
    //   let type = list[list.length-1]
    //   let maxSize = 209715200
    //   if(size>maxSize){
    //     this.msgWarn('上传文件最大为200MB')
    //     return false
    //   }
    //   if(type!='doc'&&type!='docx'&&type!='pdf'){
    //     this.msgWarn('请上传后缀为doc/docx/pdf格式的附件')
    //     return false
    //   }
    //   this.LoadingIcon = Loading.service()
    //   return true
    // },

    //文件上传成功
    // updateSuccess(response, file){
    //   this.LoadingIcon.close()
    //   if(!response.success){
    //     this.msgWarn(response.message||response.respMsg)
    //   }else{
    //     this.release.fujianPath = response.data
    //     this.fileName = file.name
    //   }
    // },

    //点击发布时
    tapRelease(){
      let {release,pdIsEmpty,selEntry,residueMoney,msgWarn,isExist} = this
      let {taskname,describe,totaldays,totalmoney,fzr_totalmoney} = release
      let arr = []
      for(let item of selEntry){
        for(let val of item){
          if(arr.indexOf(val)==-1){
            arr.push(val)
          }
        }
      }
      release.type = arr+''
      if(!pdIsEmpty(taskname)){
        msgWarn('请输入任务名称')
        return
      }
      if(isExist){
        msgWarn('当前任务名称已存在')
        return
      }
      if(!pdIsEmpty(describe)){
        msgWarn('请输入任务简介')
        return
      }
      if(!release.type){
        msgWarn('请选择词条领域')
        return
      }
      // if(!fujianPath){
      //   msgWarn('请上传附件')
      //   return
      // }
      if(!totaldays){
        msgWarn('请选择任务工期')
        return
      }
      if(!fzr_totalmoney){
        msgWarn('请输入负责人酬劳')
        return
      }
      if(!totalmoney){
        msgWarn('请输入总酬劳')
        return
      }
      if(fzr_totalmoney*1>=totalmoney*1){
        msgWarn('负责人酬劳必须小于总酬劳')
        return
      }
      if(residueMoney*1<totalmoney*1){
        this.$alert(`您的余额不足，当前账户余额为${residueMoney}知识币`, '', {
          confirmButtonText: '确定',
          callback: () => {}
        });
        return
      }
	  console.log(residueMoney,totalmoney)
      this.$confirm(`你的账户知识币余额为${residueMoney.toFixed(2)}知识币，您确定要支付${(totalmoney*1).toFixed(2)}知识币发布任务？`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.LoadingIcon = Loading.service()
        this.tapPay(release)
      }).catch(() => {
        msgWarn('用户取消支付')
      });
    },

    

    //用户点击确定支付
    async tapPay(release){
      let {residueMoney,zsjg_prepay,msgWarn} = this
      let {userid,totalmoney} = release
      let strRelease = ''
      let date = new Date()*1+''
      release.id = date.slice(date.length-9+(userid+'').length,date.length) + userid
      release.issueTime = date*1
      for(let name in release){
        strRelease += name
        if(typeof release[name] == 'string') strRelease = strRelease+':"'+release[name]+'",'    
        else strRelease = strRelease+':'+release[name]+','
      }
      strRelease = strRelease.slice(0,strRelease.length-1)

      //插入发布数据
      let installZSJG = await INSERT(
        "POST","",
        'insert_jgEntryPro(objects:{'+ strRelease +'}) {affected_rows returning {id}}'
      )
      if(!installZSJG.success){
        this.LoadingIcon.close()
        msgWarn('发布失败，请重试')
        return
      }else{
        this.setWandingProduct(release,installZSJG.data.insert_jgEntryPro.returning[0].id)
      }
    },

    //调用课题三新增数据 ——> 词条加工
    setWandingProduct(release,id){
      let {taskname,userid,username,describe,type,resultformat,totaldays,totalmoney,fzr_totalmoney,
      jgFzrAndTaskId,status,issueTime,startTimes,endTime,tasktype} = release
      let obj = {
        taskId:id,  //任务id
        taskName:taskname,  //任务名称（String)
        reward:totalmoney*1-fzr_totalmoney*1,  //任务金额(BigDecimal)
        field:type.split(','),  //任务涉及的领域(Array(String)) //多选，具体领域分类见附件field.txt
        document:[describe], //任务相关文档信息(Array(String))
      }
      post('/common/huaFenEntryTask',obj).then(res=>{
        if(res.success){
          this.deductZsb(release,id)
        }else{
          this.issueRevocation(id)
        }
      },err=>{
        this.issueRevocation(id)
      })
    },

    //扣除知识币
    async deductZsb(release,id){
      //扣除知识币
      let {residueMoney,zsjg_prepay,msgWarn} = this
      let {userid,totalmoney} = release
      let pay = await UPDATES(
        "post","",
        "update_User(_set: {Money:"+(residueMoney-totalmoney)+", zsjg_prepay:"+(zsjg_prepay*1+totalmoney*1)+"}, where: {id: {_eq: "+userid+"}}) {  affected_rows }"
      )
      if(!pay.success){
        this.issueRevocation(id)
        // msgWarn('支付失败，请重试')
        return  
      }
      this.LoadingIcon.close()
      msgWarn('发布成功','success')
      Bringbill.call(this).then(orderNum=>{
        this.installOrder(orderNum,totalmoney,userid,id)
      })
      this.setData(id)
    },

    //撤回数据
    async issueRevocation(tsakid){
      this.LoadingIcon.close()
      await DELETE(
        "post","",
        'delete_jgEntryPro(where: {id:{_eq:'+tsakid+'}}) {affected_rows}'
      )
      this.msgWarn('发布失败，请重试')
    },

    //新增订单号
    async installOrder(orderNum,totalmoney,userid,tsakid){
      await INSERT(
        "POST","",
        'insert_bill(objects:{time:"'+
          new Date()
        +'",type:4,orderNum:"'+
          orderNum
        +'",Price:"'+
          totalmoney+
        '",classification:1,userid:'+
          userid
        +'orderid:'+
          tsakid
        +',state:0}) {affected_rows}'
      )
    },

    //重新设置数据
    setData(tsakid){
      // let id = this.release.userid
      // this.selEntry = []
      // this.release={  
      //   taskname:'',describe:'',type:'',resultformat:'docx',fujianPath:'',totaldays:'',totalmoney:'',
      //   fzr_totalmoney:'',status:0,issueTime:0
      // }
      // this.fileName = ''
      // this.getUserInfo(id)
      this.$router.push({path:'/taskDetail',query:{id:tsakid,type:2,isNotBack:1}})
    }
  }
};
</script>

<style scoped>
.container {
  text-align: center;
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headClass {
  width: 1090px;
  height: 66px;
  line-height: 66px;
  text-align: left;
}
.gWbc{
  width: 1090px;
  background-color: #fff;
}
.title{
  height: 72px;
  line-height: 72px;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 3px solid #eee;
}
.basicInfo{
  /* margin-bottom: 30px; */
  padding-top: 18px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 3px solid #eee;
}
.bi_top_btn{
  width: 380px;
  height: 36px;
  background: #EEEEEE;
  border: 1px solid #D2D2D2;
  text-align: center;
  line-height: 36px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.typeIn{
  margin-top: 17px;
  display: flex;
}
.inLeft{
  padding-top: 8px;
  text-align: right;
  font-family: Microsoft YaHei;
  font-weight: 400;
  display: flex;
  align-items:flex-start;
}
.inLeft>span:first-child{
  color: #D50005;
  font-size: 20px;
}
.inLeft>span:last-child{
  margin-left: 10px;
  font-size: 14px;
  color: #000000;
}
.inRight{
  width: 390px;
  margin-left: 10px;
}
.select_input >>> .el-cascader{
  width: 100%;
}
.right_input{
  width: 100%;
  height: 36px;
}
.right_input_radio{
  line-height: 36px;
  text-align: left;
}
.right_input_btn{
  text-align: center;
  line-height: 36px;
  /* background-color: #22AC38; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FEFFFE;
  cursor: pointer;
}
.right_input_btn>>>.el-upload{
  width: 100%;
  height: 100%;
}
.right_input_btn>>>.el-button{
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 36px;
  padding:0;
  background-color: #22AC38;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FEFFFE;
  cursor: pointer;
}
.rightTs{
  margin-top: 10px;
  font-size: 14px;
  text-align: left;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #676767;
}
.rightTs>div{
  margin-left: 42px;
}
.right_input>>>.el-textarea__inner{
  height: 100px;
}
.inputs{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.inputs_btn{
  width: 25px;
  height: 25px;
  margin-left: 20px;
  border:1px solid #999;
  border-radius: 3px;
  text-align: center;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.inputs_btn>i{
  font-size: 16px;
  color: #000000;
}
.errors{
  align-items: flex-end;
}
.in_btn{
  width: 700px;
  margin-top: 70px;
  justify-content: space-between;
}
.in_btn>div{
  width: 130px;
  height: 36px;
  background-color: #EEEEEE;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #011603;
  cursor: pointer;
}
.in_btn>div:hover{
  opacity: 0.8;
}
div.inRight_bnt{
  background-color: #0084FF;
  border: 1px solid #0084FF; 
  color:#fff
}
</style>
